import { useEffect } from "react";
import { RestLoansRepository } from "../../infrastructure/repositories";
import { LoansRepository } from "../../domain/repositories";
import KRGlue from "@lyracom/embedded-form-glue";
import { toast } from "react-toastify";

const usePayzen = (loanId: string, onSubmit: (response: KRPaymentResponse) => void) => {
  const loansRepository: LoansRepository = RestLoansRepository.getInstance();
  const PUBLIC_KEY = '80880242:publickey_SP6OJMvsAV9oCVKQDVWiwEKCWtdXU8moSAjJY9VXmh2VW';
  const ENDPOINT = 'https://static.payzen.lat';
  let isFirstTime = true;

  useEffect(() => {
    async function setupPaymentForm() {
      if(!isFirstTime) return;

      const { KR } = await KRGlue.loadLibrary(ENDPOINT, PUBLIC_KEY);

      const [token, error] = await loansRepository.payzenToken(loanId);
      if(error) { toast.error(error); return; }
      
      KR.onSubmit((response: KRPaymentResponse) => { onSubmit && onSubmit(response); });
      await KR.setFormConfig({ 'kr-language': 'es-CO' })
      await KR.setFormConfig({ formToken: token })
      await KR.renderElements('#payment-form');
    }

    setupPaymentForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isFirstTime = false;
  }, []);

  return {
  }
};

export default usePayzen;