import { PageContainer } from "../components";
import { useConfig, usePageTracking } from "../hooks";

const PaymentMethodsPage = () => {
  usePageTracking();
  const { config } = useConfig();

  return (
    <PageContainer pageTitle="Métodos de pago" className="flex-col">
      <h1 className="text-4xl font-bold">Métodos de pago</h1>
      <div className="text-gray-500 flex flex-wrap flex-col bg-white mt-5 rounded-lg shadow-sm border border-gray-200 py-10 px-6 md:p-20 space-y-5">
        <p>Para pagar tu crédito, solo necesitas acceder a nuestra página web y dirigirte a la sección de Créditos. Una vez allí, selecciona el botón de PAGAR correspondiente al crédito que deseas cancelar. Este enlace te dirigirá a PSE, donde podrás realizar el pago de forma fácil, rápida y segura, utilizando diversos métodos de pago. Es importante tener en cuenta que los pagos en línea están sujetos a un recargo del {(config?.onlinePaymentFeeRate ?? 0) * 100}%. Si prefieres evitar este cargo adicional, te recomendamos hacer una transferencia bancaria.</p>
        <p>Cuando desees realizar el pago del préstamo a través de transferencia bancaria, puedes hacerlo a las siguientes cuentas:</p>

        <div className="space-y-5 overflow-x-auto">
          <table className="border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="text-xs border border-slate-300 p-2">Nombre titular</th>
                <th className="text-xs border border-slate-300 p-2">NIT titular</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-xs border border-slate-300 p-2">INVERSIONES Y PRÉSTAMOS S.A.S.</td>
                <td className="text-xs border border-slate-300 p-2">901.863.221-2</td>
              </tr>
            </tbody>
          </table>

          <table className="w-full border-collapse border border-slate-400">
            <thead>
              <tr>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Banco</th>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Tipo de Cuenta</th>
                <th className="text-xs border border-slate-300 p-0.5 lg:p-2">Numero de Cuenta</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Bancolombia</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Ahorros</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2 text-sky-400">171-000034-78</td>
              </tr>
              {/*<tr>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Bancoomeva</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2">Ahorros</td>
                <td className="text-xs border border-slate-300 p-0.5 lg:p-2 text-sky-400">0508.0283.1301</td>
  </tr>*/}
            </tbody>
          </table>
        </div>
        
        <a href="https://prestaseguro.s3.us-east-2.amazonaws.com/prestaseguro-qr-bancolombia.jpg" className="flex flex-col items-center" target="_blank" rel='noreferrer'>
          <img src="https://prestaseguro.s3.us-east-2.amazonaws.com/prestaseguro-qr-bancolombia.jpg" alt="Transferencia bancaria" className="w-1/4" />
          <span className="text-sm mx-auto">Haz click en el QR para expandir</span>
        </a>

        <p>Una vez completada la transferencia, por favor envíanos el comprobante a través de correo electrónico a: <a href={`mailto:${config?.contactEmail}?subject=Comprobante%20transaccion%20`} className="font-bold text-sky-400">{config?.contactEmail}</a>, o contáctanos vía WhatsApp al: <a href={`https://wa.me/57${config?.contactPhone}`} target="_blank" rel='noreferrer' className="font-bold text-sky-400">{config?.contactPhone}</a>. De esta manera, podremos actualizar el estado de tu crédito.</p>
      </div>
    </PageContainer>
  )
}

export default PaymentMethodsPage;