import { PageContainer, Splash, PaymentConfirmationDialog, LoanPaymentTransactionsTable, Tooltip } from "../components";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useConfig, useLoanContract, useLoanDetail, useLoanInvoice, useLoanPaymentDialog, usePageTracking } from "../hooks";
import Routes from "../../config/Routes";
import { useEffect } from "react";
import { Color, DateUtil, Money } from "../../utils";
import { Loan } from "../../domain/models";
import { LoanStatus } from "../../domain/models/loan";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

const LoanDetailLabel = ({ label, value, description, labelClass = undefined, valueClass = undefined }: { label: string, value: string, labelClass?: string, valueClass?: string, description?: string }) => {
  return (
    <div className="flex flex-col w-full p-2">
      <div className="flex flex-row space-x-2 items-center">
        <p className={`text-md font-bold text-gray-800 ${labelClass}`}>{label}</p>
        {
          description ? (<Tooltip text={description} />) : null
        }
      </div>
      <p className={`text-sm font-bold text-gray-500 ${valueClass}`}>{value}</p>
    </div>
  )
}

const LoanDetailOptions = ({ loan, setPayingLoan, setShowPaymentDialog }: { loan: Loan, setPayingLoan: React.Dispatch<React.SetStateAction<Loan | null>>, setShowPaymentDialog: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const showInvoice = loan.status === LoanStatus.paid;
  const { loading, loadInvoice } = useLoanInvoice(loan.id);

  return (
    <div className="flex flex-col md:flex-row flex-wrap pl-10 pr-10 justify-end space-y-2 md:space-y-0 space-x-0 md:space-x-2 mt-10 md:mt-0 items-end">
      {
        loan.receiptUrl ? (
          <button
            className={`bg-violet-600 hover:bg-violet-800 py-2 px-4 rounded-full text-white text-sm font-normal w-fit`}
            onClick={async () => window.open(loan.receiptUrl!, '_blank')}
          >Descargar comprobante </button>
        ) : null
      }
      <button
        disabled={!loan.availableForPayment()}
        className={`bg-violet-600 ${loan.availableForPayment() ? 'hover:bg-violet-800' : 'opacity-20'} py-2 px-4 rounded-full text-white text-sm font-normal w-fit`}
        onClick={async () => {
          setPayingLoan(loan);
          setShowPaymentDialog(true);
        }}
      >Pagar</button>
      {
        loan.providerContract?.signedContractUrl ? (
          <a
            href={loan.providerContract?.signedContractUrl}
            className={`bg-violet-600 hover:bg-violet-800 py-2 px-4 rounded-full text-white text-sm font-normal w-fit`}
          >Ver contrato firmado</a>
        ) : null
      }
      <button
        disabled={!showInvoice || loading}
        className={`bg-violet-600 ${showInvoice && !loading ? 'hover:bg-violet-800' : 'opacity-20'} py-2 px-4 rounded-full text-white text-sm font-normal w-fit`}
        onClick={async () => {
          loadInvoice();
        }}
      >Ver paz y salvo</button>
    </div>
  )
}

const LoanDetailInfo = ({ loan }: { loan: Loan }) => {
  return (
    <div className="flex flex-wrap flex-col md:flex-row p-0 md:p-10">
      {
        loan.negotiatedAmountToPay && loan.negotiatedPaymentDate ? (
          <div className="flex flex-wrap flex-col p-10 bg-yellow-100 w-full mb-10 space-y-10">
            <h2 className="text-lg font-bold text-gray-600">Acuerdo de pago</h2>
            <div className="flex flex-col">
              <LoanDetailLabel label="Monto acordado a pagar" value={Money.formatToCOP(loan.negotiatedAmountToPay)}  />
              <LoanDetailLabel label="Fecha acordada de pago" value={DateUtil.formatDate(loan.negotiatedPaymentDate)} />
            </div>
          </div>
        ) : null
      }

      <div className="flex flex-wrap flex-col p-10 w-auto md:w-1/2">
        <LoanDetailLabel label="Fecha de solicitud" value={DateUtil.formatDate(loan!.createdAt)} />
        <LoanDetailLabel label="Fecha de deposito" value={loan!.depositedAt ? DateUtil.formatDate(loan!.depositedAt!) : 'N/A'} />
        <LoanDetailLabel label="Fecha estimada de pago" value={loan!.estimatedPaymentDate ? DateUtil.formatDate(loan!.estimatedPaymentDate) : 'N/A'} valueClass={loan!.inArrears() ? 'text-red-600' : ''} labelClass={loan!.inArrears() ? 'text-red-600' : ''} />
        <LoanDetailLabel label="Fecha real de pago" value={loan!.paidAt ? DateUtil.formatDate(loan!.paidAt!) : 'N/A'} />
        <LoanDetailLabel label="Monto solicitado" value={Money.formatToCOP(loan!.loanApplication?.amount)} />
        <LoanDetailLabel label="Monto aprobado" value={loan!.amountApproved ? Money.formatToCOP(loan!.amountApproved!) : 'N/A'} />
        <LoanDetailLabel label="Monto a pagar" value={Money.formatToCOP(loan!.amountToPay ?? loan!.loanApplication?.amountToPay)} />
        <LoanDetailLabel label="Monto real pagado" value={loan!.amountPaid ? Money.formatToCOP(loan!.amountPaid!) : 'N/A'} />
        <LoanDetailLabel label="Método de pago" value={loan.paymentMethod ?? 'N/A'} />
      </div>

      <div className="flex flex-wrap flex-col p-10 bg-gray-100 w-auto md:w-1/2">
        {
          loan.amountToPay && loan.amountApproved ? (
            <>
              <LoanDetailLabel label="Préstamo" value={Money.formatToCOP(loan.amountApproved)} />
              <LoanDetailLabel label="Interés corriente" value={Money.formatToCOP(loan.chargedInterest!)} description={loan.interestDescription} />
              <LoanDetailLabel label="Cuota de manejo" value={Money.formatToCOP(loan.chargedMaintenanceFee!)} description={loan.maintenanceFeeDescription} />
              {
                loan.fees.map((fee, index) => {
                  return (
                    <LoanDetailLabel key={index} label={fee.fee.name} value={Money.formatToCOP(fee.amount)} description={fee.fee.description} />
                  )
                })
              }
              <LoanDetailLabel label="IVA" value={Money.formatToCOP(loan.chargedIvaFee!)} description={loan.ivaFeeDescription} />
              <div className="border-t border-gray-300"></div>
              <LoanDetailLabel label="Total" value={loan!.amountToPay ? Money.formatToCOP(loan!.amountToPay) : 'N/A'} />
            </>
          ) : (
            <>
              <LoanDetailLabel label="Préstamo" value={Money.formatToCOP(loan.loanApplication?.amount)} />
              <LoanDetailLabel label="Interés corriente" value={Money.formatToCOP(loan.loanApplication.chargedInterest)} description={loan.loanApplication.interestDescription} />
              <LoanDetailLabel label="Cuota de manejo" value={Money.formatToCOP(loan.loanApplication.chargedMaintenanceFee)} description={loan.loanApplication.maintenanceFeeDescription} />
              {
                loan.loanApplication?.fees.map((fee, index) => {
                  return (
                    <LoanDetailLabel key={index} label={fee.fee.name} value={Money.formatToCOP(fee.amount)} description={fee.fee.description} />
                  )
                })
              }
              <LoanDetailLabel label="IVA" value={Money.formatToCOP(loan.loanApplication.chargedIvaFee)} description={loan.loanApplication.ivaFeeDescription} />
              <div className="border-t border-gray-300"></div>
              <LoanDetailLabel label="Total" value={loan!.loanApplication?.amountToPay ? Money.formatToCOP(loan!.loanApplication?.amountToPay) : 'N/A'} />
            </>
          )
        }
      </div>
    </div>
  )
}

/*const LoanDetailUploadFile = ({ loan }: { loan: Loan }) => {
  const { supportFile, setSupportFile, submitForm, loading } = useLoanSupportFile();

  return (
    <div className="flex flex-wrap flex-col p-10">
      <h3 className="text-xl font-bold">Subir archivo de soporte</h3>
      <p className="text-sm text-gray-500 text-lg">En caso de que se te sea solicitado puedes subir tus archivos de soporte en este lugar.</p>

      <form className="mt-5" onSubmit={(event) => { event.preventDefault(); submitForm(loan.id); }}>
        <FileInputComponent
          onChange={(file) => { setSupportFile(FileInput.dirty(file)) }}
          value={supportFile.value}
          disabled={loading}
        />

        <Button loading={loading} disabled={InputValidator.anyEmpty([supportFile])} className="mt-8 w-auto h-6" text="Subir" type='submit' />
      </form>
    </div>
  )
}*/

const LoanStatusHeader = ({ loan }: { loan: Loan }) => {
  const { config } = useConfig();
  const { loading, loadContractFile } = useLoanContract(loan.id);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signedParam = queryParams.get('signed');

  if (loan?.status === LoanStatus.open) {
    return (
      <div className="flex flex-col m-10 space-y-10">
        <p className="text-sm text-gray-500 text-lg bg-yellow-100 p-5 rounded-lg">Tu solicitud de crédito ha sido recibida y actualmente está en proceso de aprobación. Una vez tengamos una respuesta, te notificaremos por correo electrónico. ¡Gracias por elegirnos!</p>
      </div>
    )
  } else if (loan?.status === LoanStatus.rejected) {
    return (
      <div className="flex flex-col m-10 space-y-10">
        <p className="text-sm text-gray-500 text-lg bg-red-100 p-5 rounded-lg">Lamentablemente, no pudimos aprobar tu solicitud de crédito en este momento. Estamos constantemente mejorando nuestros sistemas de verificación y esperamos poder considerar tu solicitud en el futuro. ¡Gracias por tu comprensión y apoyo!</p>
      </div>
    )
  } else if (loan?.status === LoanStatus.approved) {
    if (loan.signedContract) {
      return (
        <div className="flex flex-col m-10 space-y-10">
          <p className="text-sm text-gray-500 text-lg bg-green-100 p-5 rounded-lg">¡Felicitaciones! Tu crédito ha sido aprobado y el contrato ha sido firmado y procesado correctamente. Muy pronto verás el monto aprobado en tu cuenta registrada, a más tardar en un día hábil. ¡Gracias por confiar en nosotros!</p>
        </div>
      );
    } else if (signedParam === 'true') {
      return (
        <div className="flex flex-col m-10 space-y-10">
          <p className="text-sm text-gray-500 text-lg bg-cyan-100 p-5 rounded-lg">¡Gracias por firmar el contrato! Estamos procesando tu documentación. Te notificaremos en cuanto validemos la información y procedamos con la consignación del dinero en tu cuenta registrada.</p>
        </div>
      )
    } else if (loan.acceptedOnlineSignature) {
      return (
        <NavLink
          to={Routes.SIGN_CONTRACT_PATH.replace(':id', loan.id)}
          className="flex flex-col sm:flex-row bg-yellow-100 p-5 m-10 rounded-lg justify-center items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0"
        >
          <DocumentScannerIcon />
          <div className="text-sm text-gray-500 text-lg text-start">¡Felicitaciones! Tu crédito ha sido aprobado. Para que podamos realizar la consignación del dinero en tu cuenta registrada, es necesario que firmes el contrato del préstamo en línea. Para hacerlo, por favor ingresa al siguiente <span className="text-violet-500">enlace</span>.</div>
          <span className="hidden sm:block"><ArrowForwardIosIcon style={{ fontSize: '1rem' }} /></span>
        </NavLink>
      );
    } else {
      return (
        <button
          className={`${loading ? 'bg-gray-100' : 'bg-yellow-100'} flex flex-col sm:flex-row p-5 m-10 rounded-lg justify-center items-center space-x-0 sm:space-x-4 space-y-4 sm:space-y-0`}
          disabled={loading}
          onClick={() => { loadContractFile(); }}
        >
          <DocumentScannerIcon />
          <div className="text-sm text-gray-500 text-lg text-start">¡Felicidades! Tu crédito ha sido aprobado. Para poder aceptarlo, debes descargar el contrato de préstamo, firmarlo en una notaría y llevar el documento firmado a nuestras oficinas en la dirección <strong>{config?.contactAddress}</strong>. Una vez validado el contrato por nosotros, procederemos a consignar el dinero en tu cuenta registrada. Para descargar el contrato, ingresa al siguiente <span className="text-violet-500">enlace</span>. Para más información, no dudes en contactarnos a través de nuestros canales de atención.</div>
          <span className="hidden sm:block"><ArrowForwardIosIcon style={{ fontSize: '1rem' }} /></span>
        </button>
      );
    }
  }
  else {
    return null;
  }
}


const LoanDetailPage = () => {
  const { id } = useParams();
  const {
    loading,
    loadLoan,
    loan
  } = useLoanDetail();
  const {
    showPaymentDialog,
    payingLoan,
    setShowPaymentDialog,
    setPayingLoan
  } = useLoanPaymentDialog();
  usePageTracking();

  useEffect(() => {
    loadLoan(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !loan) {
    return (<Splash />)
  }

  return (
    <PageContainer className="flex-col">
      <div className="flex flex-wrap flex-row space-x-5">
        <h1 className="text-4xl font-bold">Crédito #{loan.consecutive}</h1>

        <div className={`px-3 py-2 text-sm font-normal rounded-full ${Color.loanStatusToBgColor(loan.status)}`}>
          {loan.status}
        </div>
      </div>

      <div className="mt-8 bg-white rounded-lg shadow-lg flex-col flex">
        {
          showPaymentDialog &&
          <PaymentConfirmationDialog
            loan={payingLoan}
            onCancel={() => {
              setShowPaymentDialog(false);
              setPayingLoan(null);
            }}
            onResult={() => {
              setShowPaymentDialog(false);
              setPayingLoan(null);
              loadLoan(id!);
            }}
          />
        }

        <NavLink className="flex justify-start pl-10 pt-10 items-center font-bold opacity-65" to={Routes.HOME_PATH}>
          <ArrowBackIosIcon style={{ fontSize: '1rem' }} className="text-gray-500 hover:text-gray-400" />
          Volver
        </NavLink>

        <LoanStatusHeader loan={loan} />
        <LoanDetailOptions loan={loan} setPayingLoan={setPayingLoan} setShowPaymentDialog={setShowPaymentDialog} />
        <LoanDetailInfo loan={loan} />
        <LoanPaymentTransactionsTable loanId={loan.id} />
      </div>
    </PageContainer>
  )
}

export default LoanDetailPage;