import { useEffect } from "react";
import { Color, DateUtil, Money } from "../../utils";
import useLoans from "../hooks/useLoans";
import Table from "./shared/Table";
import { NavLink } from "react-router-dom";
import Routes from "../../config/Routes";
import PaymentConfirmationDialog from "./shared/PaymentConfirmationDialog";
import { useLoanPaymentDialog } from "../hooks";
import { LoanStatus } from "../../domain/models/loan";

const LoansTable = () => {
  const { loans, totalPages, page, loadUserLoans, loading } = useLoans();
  const columnNames = ['', 'Estado', 'Crédito', 'Valor a pagar', 'Fecha de pago', 'Acciones'];
  let rows: React.ReactNode[][] = [];
  const {
    showPaymentDialog,
    setShowPaymentDialog,
    payingLoan,
    setPayingLoan
  } = useLoanPaymentDialog();

  useEffect(() => {
    loadUserLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  loans?.forEach(loan => {    
    rows.push([
      <div>
        <h2 className="font-medium text-gray-800 dark:text-white">Préstamo personal</h2>
        <p className="text-sm font-normal text-gray-600 dark:text-gray-400">{DateUtil.formatDate(loan.createdAt)}</p>
        {
          loan.status === LoanStatus.approved && !loan.signedContract ? (
            <NavLink className="text-xs font-light mt-2 underline" to={Routes.LOAN_DETAIL_PATH.replace(':id', loan.id)}>
              Pendiente por firmar contrato
            </NavLink>
          ) : null
        }
      </div>,
      <div className={`inline px-3 py-2 text-sm font-normal rounded-full ${Color.loanStatusToBgColor(loan.status)} gap-x-2`}>
        {loan.status}
      </div>,
      <div className="text-sm font-bold text-gray-600 dark:text-gray-400">
        {Money.formatToCOP(loan.amountApproved ?? loan.loanApplication.amount)} COP
      </div>,
      <div className="text-sm font-bold text-gray-600 dark:text-gray-400">
        {
          loan.negotiatedAmountToPay ? (
            <>
              <div>{Money.formatToCOP(loan.negotiatedAmountToPay)} COP</div>
              <div className="text-sm font-thin">Acuerdo de pago</div>
            </>
          ) : (
            <div>{Money.formatToCOP(loan.amountToPay ?? loan.loanApplication.amountToPay)} COP</div>
          )
        }
      </div>,
      <div className={`text-sm font-bold text-gray-600 dark:text-gray-400 ${loan.inArrears() ? 'text-red-600' : ''}`}>
        {loan.estimatedPaymentDate ? DateUtil.formatDate(loan.estimatedPaymentDate) : '-' }
      </div>,
      <div className="flex flex-row flex-wrap space-x-4 justify-center sm:justify-start items-center space-y-4 sm:space-y-0">
        <NavLink to={Routes.LOAN_DETAIL_PATH.replace(':id', loan.id)} className="text-sm font-normal text-violet-800 hover:text-violet-900">Detalles</NavLink>
        <button
          disabled={!loan.availableForPayment()}
          className={`bg-violet-600 ${loan.availableForPayment() ? 'hover:bg-violet-800' : 'opacity-20'} py-2 px-4 rounded-full text-white text-sm font-normal`}
          onClick={() => {
            setPayingLoan(loan);
            setShowPaymentDialog(true);
          }}
        >Pagar</button>
      </div>
    ]);
  });

  if(loans?.length === 0) {
    return(
      <div className="flex flex-wrap flex-col justify-center items-center mt-5 md:mt-20">
        <h2 className="font-bold text-xl opacity-50 text-center">Por el momento no tienes créditos</h2>
        <p className="font-normal text-sm text-center opacity-50">Aquí estarán los detalles de tus creditos después de que solicites tu primer préstamo</p>
      </div>
    )
  } else {
    return (
      <>
        {
          showPaymentDialog &&
          <PaymentConfirmationDialog
            loan={payingLoan}
            onCancel={() => {
              setShowPaymentDialog(false);
              setPayingLoan(null);
            }}
            onResult={() => {
              setShowPaymentDialog(false);
              setPayingLoan(null);
              loadUserLoans();
            }}
          />
        }
        <Table loading={loading} columnNames={columnNames} rows={rows} totalPages={totalPages} currentPage={page} onPageChange={(page) => {
          loadUserLoans(page);
        }} />
      </>
    )
  }
}

export default LoansTable;